(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/wallet/assets/javascripts/wallet-services.js') >= 0) return;  svs.modules.push('/components/tipsen/wallet/assets/javascripts/wallet-services.js');
"use strict";


const {
  jupiter
} = svs.core;
const {
  get
} = svs.components.account_balance;
const {
  getAccountBalance
} = svs.components.marketplaceShared.getBalance;
const fetchMarketplacePlayerWallets = _ref => {
  let {
    role,
    productId,
    drawNumber,
    activatedDrawId
  } = _ref;
  return new Promise((resolve, reject) => {
    const fetchBalance = async () => {
      try {
        const allBalances = await getAccountBalance.getAllBalancesForDraw({
          role,
          productId,
          drawNumber,
          activatedDrawId
        });
        const activatedDraws = Array.from(allBalances.values()).map(balance => balance.data).filter(activatedDraw => activatedDraw.drawNumber === drawNumber);
        resolve(activatedDraws);
      } catch (error) {
        reject(error);
      }
    };
    fetchBalance();
  });
};
const fetchMarketplaceRetailWallet = activatedDrawId => new Promise((resolve, reject) => {
  jupiter.get("/retailer/1/marketplace/activateddraws/".concat(activatedDrawId), response => {
    resolve(response.activatedDraw);
  }, error => {
    reject(error);
  });
});
const fetchPlayerWallet = () => new Promise((resolve, reject) => {
  get((balance, error) => {
    if (error) {
      reject(error);
    } else {
      resolve(balance);
    }
  });
});
setGlobal('svs.components.tipsen.wallet.services', {
  fetchMarketplacePlayerWallets,
  fetchMarketplaceRetailWallet,
  fetchPlayerWallet
});

 })(window);